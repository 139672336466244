/* ---------------------------------
Stylesheet Responsive for Apecsa Optima Solusi

version: 2.0
author : Fathan Rohman
email  : karir.fathan@gmail.com
website: http://apecsa-indonesia.com
-----------------------------------*/

@media screen and (max-width: 1500px) {

}

@media screen and (max-width: 1200px) {

}

@media screen and (max-width: 1024px) {
	
}

@media screen and (max-width: 950px) {
	
}

@media screen and (max-width: 850px) {

}

@media screen and (max-width: 768px) {

}

@media screen and (max-width: 650px) {
	
}

@media screen and (max-width: 550px) {

}

@media screen and (max-width: 450px) {
	
}

@media screen and (max-width: 350px) {

}

@media screen and (max-width: 320px) {

}
